.navbarContainer {
  position: fixed;
  left: 0;
  top: 0;
  order: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  background-color: #012456;
}
.navbarLinks {
  border: dotted green;
}

.showNavbar {
  display: flex;
}

.hideNavbar {
  display: flex;
}

.button {
  display: flex;
  flex-wrap: wrap;
  border-radius: 50px;
  border: none;
  color: white;
  padding: 1.5vh;
  margin: 1vh;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-decoration: none;
  font-size: 1.75em;
}
.button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.burgerButton {
  display: none;
}
@media screen and (max-width: 480px) {
  .burgerButton {
    display: flex;
    border-radius: 10px;
    border-style: outset;
    border-color: gray;
    background-color: #012456;
    color: white;
    padding: 1.5vh;
    margin: 0.5vh;
    font-family: "Roboto", sans-serif;
    text-align: center;
    text-decoration: none;
    font-size: 1.75em;
    position: fixed;
    left: 0;
    top: 0;
    order: 0;
    z-index: 10;
  }
  .burgerButton:hover {
    border-style: inset;
  }
  .hideNavbar {
    display: none;
  }
  .showNavbar {
    order: 1;
    width: 40vw;
    flex-wrap: wrap;
    position: relative;
  }
}
