@import url(https://fonts.googleapis.com/css2?family=Roboto&family=VT323&display=swap&effect=anaglyph);
.navbarContainer {
  position: fixed;
  left: 0;
  top: 0;
  order: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  background-color: #012456;
}
.navbarLinks {
  border: dotted green;
}

.showNavbar {
  display: flex;
}

.hideNavbar {
  display: flex;
}

.button {
  display: flex;
  flex-wrap: wrap;
  border-radius: 50px;
  border: none;
  color: white;
  padding: 1.5vh;
  margin: 1vh;
  font-family: "Roboto", sans-serif;
  text-align: center;
  text-decoration: none;
  font-size: 1.75em;
}
.button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.burgerButton {
  display: none;
}
@media screen and (max-width: 480px) {
  .burgerButton {
    display: flex;
    border-radius: 10px;
    border-style: outset;
    border-color: gray;
    background-color: #012456;
    color: white;
    padding: 1.5vh;
    margin: 0.5vh;
    font-family: "Roboto", sans-serif;
    text-align: center;
    text-decoration: none;
    font-size: 1.75em;
    position: fixed;
    left: 0;
    top: 0;
    order: 0;
    z-index: 10;
  }
  .burgerButton:hover {
    border-style: inset;
  }
  .hideNavbar {
    display: none;
  }
  .showNavbar {
    order: 1;
    width: 40vw;
    flex-wrap: wrap;
    position: relative;
  }
}

html {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  min-height: 100%;
  background: #23272a;
  color: white;
}
p > a:link {
  color: rgb(87, 242, 135);
}
p > a:visited {
  color: rgb(254, 231, 92);
}
.column {
  display: flex;
  flex-direction: row;
}

.leftColumn {
  background-color: #012456;
  flex: 1 1;
}
/*Fix the width for mobile potentially? width vw setting was to keep the container from shrinking in desktop view -SB*/
.middleColumn {
  background-color: #2c2f33;
  flex: 5 1;
}
/*Fix height for mobile? height vh setting is to keep vertical container shrinkage, was not an issue until ipsum text removed -SB*/
.rightColumn {
  background-color: #1780be;
  flex: 1 1;
  min-height: 65vh;
}

.contentShadow {
  padding: 2vh;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.5);
}

.currentPage {
  background-color: rgba(0, 0, 0, 0.25);
}

.title {
  margin-top: 7vh;
  padding: 2vh;
  font-family: "VT323", monospace;
  color: white;
  font-size: 4.5em;
  text-align: center;
}

.mainText {
  justify-content: center;
  padding: 5vh;
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 1.5em;
}

.leftText {
  font-size: 0.75em;
  padding: 12px 16px;
}

.leftTitle {
  font-size: 1.25em;
  font-family: "VT323", monospace;
}

.bioText {
  font-size: 0.75em;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
}

.bioName {
  font-size: 1.25em;
  font-family: "VT323", monospace;
}

.eventTitle {
  font-size: 2.75em;
  padding: 1vh;
  font-family: "Roboto", monospace;
}
.eventText {
  font-size: 1.5em;
  padding: 1vh;
  text-indent: 5%;
}

.arcade1 {
  text-align: center;
}

.egg {
  justify-content: end;
  -webkit-text-decoration-color: beige;
          text-decoration-color: beige;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 2.5em;
}
.egg a:link,
.egg a:visited {
  color: beige;
}
.egg a:hover {
  background-color: green;
}

div.mobcal {
  display: none;
}

.deskCal {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .column {
    flex-direction: column;
  }
  .leftColumn {
    order: 2;
  }
  .middleColumn {
    order: 1;
  }
  .rightColumn {
    order: 3;
  }
}

